/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {medModal: false};

export const showMedSlice = createSlice({
    name: 'visible',
    initialState: { visible: initialState },
    reducers: {
      showMed: (state, { payload }) => {
        state.visible = { ...payload }
      },
    },
  })
  
export const { showMed } = showMedSlice.actions

export default showMedSlice.reducer;