/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {

  // centerid: null,
  orphanfamilyid: null,
  // serial: null,
  // orphanfamilyaccountid: null,
  recid: null,
  orphanfamilybankid:{ value: '', label: '' },
  orphanfamilybankbranchid: { value: '', label: '' },
  acccountstatus: { value: '', label: '' },
  accountnumber: null,
  note: null,
  orphanfamilyaccountid:null,


  action: ACTION_ENUM.PREVIEW,
}
export const theMainData = createSlice({
  name: 'visibleMainData',
  initialState: { visibleMainData: initialValue },
  reducers: {
    setMainDataFamiliesAccounts: (state, action) => {
      state.visibleMainData = action.payload
    },
    cleanUpMainDataAccounts: (state) => {
      state.visibleMainData = initialValue
    },
  },
})

export const { setMainDataFamiliesAccounts, cleanUpMainDataAccounts } = theMainData.actions
export default theMainData.reducer
