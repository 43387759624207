/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  feild_name: '',
  op: '',
  value: '',
  searchStep: null,
  isSearch: false,
}
export const searchSlice = createSlice({
  name: 'search',
  initialState: { search: initialValue },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setSearchStep: (state, action) => {
      state.search.searchStep = action.payload
    },
    cleanUpSearch: (state) => {
      state.search = initialValue
    },
  },
})

export const { setSearch, setSearchStep, cleanUpSearch } = searchSlice.actions
export default searchSlice.reducer
