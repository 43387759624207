/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  centerid: null,
  centername: '',
  orphanfamilyid: null,
  applicationdate: null,
  applicationlongdate: null,
  supplierfullname: '',
  supplierrelationship: null,
  supplierrelationshiptext: '',
  fatherfirstname: '',
  fathersecondname: '',
  fatherthirdname: '',
  familyname: '',
  fathernationalnumber: '',
  fatherbirthdate: null,
  fatherbirthplace: null,
  fatherdeathdate: '',
  fatherdeathreason: null,
  fatherscientificqualification: null,
  motherfullname: ' ',
  mothernationalnumber: ' ',
  motherbirthdate: null,
  motherbirthplace: null,
  motherscientificqualification: null,
  motherjob: '',
  mothermaritalstatus: null,
  mothermaritalstatustext: '',
  city: null,
  citytext: null,
  street: '  ',
  hometelephone: null,
  neighborhood: '',
  sponsorshipstate: null,
  sponsorshipstatetext: '',
  familyneedmeter: null,
  familyneedmetertext: '',
  approved: true,
  identifier1fullname: null,
  identifier1address: null,
  identifier1phone: null,
  identifier2fullname: null,
  identifier2address: null,
  identifier2phone: null,
  researcherrecomendation: '',
  researcher: null,
  researchdate: null,
  researcheragree: null,
  researchcommitteerecomendation: null,
  researchcommitteedecisionnumber: null,
  researchcommitteedecisiondate: null,
  researchcommitteeagree: null,
  managerdecision: null,
  manager: null,
  managerdecisiondate: null,
  managerdecisionlongdate: null,
  manageragree: null,
  inactive: null,
  fathernationality: null,
  fatherjob: null,
  fathermonthlyincome: null,
  mothernationality: null,
  nearestphone: null,
  note: '',
  suppliersex: null,
  suppliersextext: '',
  fatherfullname: '',
  motherdeathdate: '',
  motherincome: '',
  fatherretirement: null,
  motherworkingstatus: null,
  suppliernationalnumber: '',
  committeeid: null,
  committeeareaid: null,
  committeeareaidtext: null,
  fatherdeathcertification: null,
  supplierid: null,
  motherdeathcertification: null,
  guardianshippaper: null,
  fatherfirstnameenglish: '',
  fathersecondnameenglish: '',
  fatherthirdnameenglish: '',
  familynameenglish: '',
  supplierfullnameenglish: '',
  motherfullnameenglish: ' ',
  fatherfullnameenglish: ' ',
  classification: null,
  classificationtext: '',
  nfamilyAction: ACTION_ENUM.PREVIEW,
}
export const NotApprovedFamilyData = createSlice({
  name: 'visibleNAOrphansFamilyData',
  initialState: { visibleNAOrphansFamilyData: initialValue },
  reducers: {
    setNAOrphanFamilyData: (state, action) => {
      state.visibleNAOrphansFamilyData = action.payload
    },
    cleanNAUpOrphanFamilyData: (state) => {
      state.visibleNAOrphansFamilyData = initialValue
    },
  },
})

export const { setNAOrphanFamilyData, cleanNAUpOrphanFamilyData } = NotApprovedFamilyData.actions
export default NotApprovedFamilyData.reducer
