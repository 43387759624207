/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    show: false,
}
export const upsertServiceModalSlice = createSlice({
    name: "visible",
    initialState: { visible: initialValue },
    reducers: {
        showServiceModal: (state, action) => {
            state.visible = action.payload;
        }
    }
})

export const { showServiceModal } = upsertServiceModalSlice.actions
export default upsertServiceModalSlice.reducer;
