/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    show: false,
}
export const deleteServiceSlice = createSlice({
    name: "visible",
    initialState: { visible: initialValue },
    reducers: {
        showDeleteServiceModal: (state, action) => {
            state.visible = action.payload;
        }
    }
})

export const { showDeleteServiceModal } = deleteServiceSlice.actions
export default deleteServiceSlice.reducer;
