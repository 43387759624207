/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const counterState = { counter: 0 }

const counterSlice = createSlice({
  name: 'counter',
  initialState: counterState,

  reducers: {
    zeroCounter: (state) => {
      state.counter = 0
    },

    anychoice:(state,action) => {
      state.counter = action.payload
    },
  },
})

export const {  anychoice,zeroCounter } = counterSlice.actions

export default counterSlice.reducer
