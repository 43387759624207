/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = { show: false }

const deleteModalSlice = createSlice({
  name: 'visible',
  initialState:  initialState  ,

  reducers: {
    showDeleteModaldepartment: (state) => {
      //show
      state.show = !state.show
    },
  },
})

export const { showDeleteModaldepartment } = deleteModalSlice.actions

export default deleteModalSlice.reducer
//store r
