/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    minorList: [],
    isSubmitted: false,
}
export const minorListSlice = createSlice({
    name: "visibleMinorList",
    initialState: { visibleMinorList: initialValue },
    reducers: {
        setMinorList: (state, action) => {
            state.visibleMinorList = action.payload;
        }
    }
})

export const { setMinorList } = minorListSlice.actions
export default minorListSlice.reducer;
