/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid:null,
  codeid: null,
  countrycodeid: null,
  country: { value: '', label: '' },
  action: ACTION_ENUM.PREVIEW,
}
export const callingCode = createSlice({
  name: 'visibleCallingCode',
  initialState: { visibleCallingCode: initialValue },
  reducers: {
    setCallingCodes: (state, action) => {
      state.visibleCallingCode = action.payload
    },
    cleanUpCallingCodes: (state) => {
      state.visibleCallingCode = initialValue
    },
  },
})

export const { setCallingCodes, cleanUpCallingCodes } = callingCode.actions
export default callingCode.reducer
