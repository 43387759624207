/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = { deleteModal: false }

export const deleteMedSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialState },
  reducers: {
    show: (state, { payload }) => {
      state.visible = { ...payload }
    },
  },
})

export const { show } = deleteMedSlice.actions

export default deleteMedSlice.reducer
