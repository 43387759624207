/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = { showModal: false }

const modalSlice = createSlice({
  name: 'visible',
  initialState: initialState,

  reducers: {
    showModaldepartment: (state) => {
      state.showModal = !state.showModal
    },
  },
})

export const { showModaldepartment } = modalSlice.actions

export default modalSlice.reducer
